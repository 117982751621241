import useStyles from './styles';
import React, { useMemo } from 'react';
import { Button, CardContent, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  productPropType,
  tariffLabels,
  tariffPropType,
} from 'app/redux/modules/Mobile/constants';
import { formatDataUsage } from 'modules/Mobile/helpers';
import { BinIcon, EditIcon } from '@utilitywarehouse/partner-ui-icons';
import { FLAGS } from 'app/redux/modules/FeatureFlags/constants';
import Link from 'modules/Shared/Link';
import { useIsMobile } from 'app/components/modules/OrderSummary/ServicesTotal/Save/utils';
import Tile from 'modules/Mobile/SimSelection/Tile';
import TariffPrice from 'modules/Shared/PriceWithDiscount';
import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';

const getIconSrc = (tariffLabel) => {
  switch (tariffLabel) {
    case tariffLabels.UNLIMITED_PLUS:
    case tariffLabels.UNLIMITED:
      return 'images/icons/unlimitedSimIcon.svg';
    case tariffLabels.ESSENTIAL:
    case tariffLabels.VALUE:
      return 'images/icons/valueSimIcon.svg';
    default:
      return 'images/icons/valueSimIcon.svg';
  }
};

const EditSimButton = ({ text, onClick }) => {
  const classes = useStyles();
  return (
    <Button
      variant={'text'}
      className={classes.editSimButton}
      onClick={onClick}
      size={'large'}
    >
      <span className={classes.editSimButtonText}>{text}</span>
      <EditIcon />
    </Button>
  );
};

EditSimButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const RemoveButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Link onClick={onClick} classes={{ root: classes.removeButton }}>
      <BinIcon />
      <Typography component="span" className={classes.removeLabel}>
        Remove
      </Typography>
    </Link>
  );
};

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const SimTile = ({
  tariff,
  product,
  openSpendCapDialog,
  openNumberOptionsDialog,
  removeProduct,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const newMobileTariffs = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.NEW_MOBILE_TARIFFS)
  );

  const numberOptionsText = useMemo(() => {
    if (product.options.values.keepMyNumber) {
      return product.options.values.existingNumber;
    }
    if (product.options.values.cancelOldContract) {
      return 'New (STAC)';
    }

    return 'New';
  }, [product.options.values]);

  return (
    <Tile actionButton={!isMobile && <RemoveButton onClick={removeProduct} />}>
      <CardContent className={classes.cardContent}>
        <div className={classes.titleWrapper}>
          <img src={getIconSrc(tariff.label)} alt="sim" />
          <Typography variant="h4" component="span" className={classes.title}>
            {tariff.name}
          </Typography>
        </div>

        <div className={classes.infoRow}>
          <Typography className={classes.infoLabel}>Data:</Typography>
          <Typography>
            <strong>{formatDataUsage(tariff)}</strong>
          </Typography>
        </div>

        <div className={classes.infoRow}>
          <Typography className={classes.infoLabel}>Budget control:</Typography>
          <EditSimButton
            text={product.options.values.budgetControl ? 'On' : 'Off'}
            onClick={openSpendCapDialog}
          />
        </div>

        <div className={classes.infoRow}>
          <Typography className={classes.infoLabel}>Number:</Typography>
          <EditSimButton
            text={numberOptionsText}
            onClick={openNumberOptionsDialog}
          />
        </div>

        <hr className={classes.separator} />

        <div className={classes.bottomRow}>
          <div>
            <TariffPrice
              price={tariff.price}
              discountedPrice={
                product.discounted ? tariff.discountedPrice : undefined
              }
            />
            {newMobileTariffs && (
              <Typography className={classes.smallText}>
                (Monthly rolling contract)
              </Typography>
            )}
          </div>
          {isMobile && <RemoveButton onClick={removeProduct} />}
        </div>
      </CardContent>
    </Tile>
  );
};

SimTile.propTypes = {
  tariff: tariffPropType,
  product: productPropType.isRequired,
  openSpendCapDialog: PropTypes.func.isRequired,
  openNumberOptionsDialog: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

export default SimTile;
