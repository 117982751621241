import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import {
  MAX_SIM_SELECTION,
  productPropType,
  tariffPropType,
} from 'app/redux/modules/Mobile/constants';
import SimTile from 'modules/Mobile/SimSelection/SimTile';
import AddSimTile from 'modules/Mobile/SimSelection/AddSimTile';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gap: `${theme.spacing(2)}px`,
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, max-content))',
    },
  },
}));

const SimSelection = ({
  selectedProducts,
  selectedTariff,
  nextSimDiscounted,
  openSpendCapDialog,
  openNumberOptionsDialog,
  removeProduct,
  addProduct,
}) => {
  const classes = useStyles();
  const trackOnceBudgetControlSelected = useTrackOnce();
  const trackOnceNumberOptionsSelected = useTrackOnce();
  return (
    <div className={classes.container}>
      {selectedProducts.map((product) => (
        <SimTile
          key={product.id}
          product={product}
          tariff={selectedTariff}
          openSpendCapDialog={() => {
            openSpendCapDialog(
              product.id,
              product.options.values.budgetControl
            );
            trackOnceBudgetControlSelected(
              EVENTS.MOBILE_EDIT_BUDGET_CONTROL_CLICKED
            );
          }}
          openNumberOptionsDialog={() => {
            openNumberOptionsDialog(product.id, product.options.values);
            trackOnceNumberOptionsSelected(EVENTS.MOBILE_EDIT_NUMBER_CLICKED);
          }}
          removeProduct={() => removeProduct(product.id)}
        />
      ))}
      {selectedProducts.length < MAX_SIM_SELECTION && (
        <AddSimTile
          tariff={selectedTariff}
          discounted={nextSimDiscounted}
          addProduct={addProduct}
        />
      )}
    </div>
  );
};

SimSelection.propTypes = {
  selectedTariff: tariffPropType,
  selectedProducts: PropTypes.arrayOf(productPropType).isRequired,
  nextSimDiscounted: PropTypes.bool.isRequired,
  openSpendCapDialog: PropTypes.func.isRequired,
  openNumberOptionsDialog: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
};

export default SimSelection;
