import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Card from './Card';
import {
  defaultTariffError,
  tariffLabels,
  tariffPropType,
  tariffStatus,
} from 'app/redux/modules/Mobile/constants';
import CardSkeleton from 'modules/Mobile/TariffSelection/Skeleton';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(0, 2),
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  itemWrapper: {
    alignItems: 'stretch',
    flexBasis: 340,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
    },
  },
}));

const TariffSelection = ({
  tariffRequestStatus,
  tariffRequestError,
  tariffs,
  onSelectTariff,
  selectedTariffLabel,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {tariffRequestStatus === tariffStatus.ERROR && (
        <Typography>{tariffRequestError || defaultTariffError}</Typography>
      )}
      {tariffRequestStatus === tariffStatus.LOADING &&
        Object.keys(tariffLabels).map((label) => (
          <div key={label} className={classes.itemWrapper}>
            <CardSkeleton />
          </div>
        ))}
      {tariffRequestStatus === tariffStatus.SUCCESS &&
        tariffs.map((tariff) => (
          <div key={tariff.label} className={classes.itemWrapper}>
            <Card
              onSelectTariff={() => onSelectTariff(tariff.label)}
              tariffSelected={tariff.label === selectedTariffLabel}
              tariff={tariff}
              hasMultiSimOffer={
                tariff.label === tariffLabels.UNLIMITED_PLUS ||
                tariff.label === tariffLabels.UNLIMITED
              }
            />
          </div>
        ))}
    </div>
  );
};

TariffSelection.propTypes = {
  tariffRequestStatus: PropTypes.string,
  tariffRequestError: PropTypes.string,
  onSelectTariff: PropTypes.func.isRequired,
  selectedTariffLabel: PropTypes.string,
  tariffs: PropTypes.arrayOf(tariffPropType),
};

export default TariffSelection;
