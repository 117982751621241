import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import { formatPrice } from 'redux/utils/formatter';
import Card from 'components/modules/Shared/Card/Card';
import CardHeader from 'components/modules/Shared/Card/CardHeader';
import useStyles from './styles';
import {
  tariffLabels,
  tariffPropType,
} from 'app/redux/modules/Mobile/constants';
import { TickMediumIcon } from '@utilitywarehouse/react-icons';
import { formatDataUsage } from 'modules/Mobile/helpers';

const TariffSelectionCard = ({
  tariff,
  tariffSelected,
  hasMultiSimOffer,
  onSelectTariff,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.topBar}>
        <div
          className={classNames(classes.multiSimContainer, {
            [classes.multiSimHidden]: !hasMultiSimOffer,
          })}
        >
          <Typography variant="caption" className={classes.multiSimText}>
            Multi-SIM offer
          </Typography>
        </div>
      </div>
      <Card classes={{ root: classes.card }}>
        <CardHeader title={tariff.name} />
        <CardContent classes={{ root: classes.content }}>
          <div className={classNames(classes.cardSection, classes.cardTitle)}>
            <Typography>
              {getTariffDesc(tariff.label, tariff.discountedPrice)}
            </Typography>
          </div>
          <div className={classes.cardSection}>
            <Benefits
              label={tariff.label}
              data={tariff.data}
              dataUnits={tariff.dataUnits}
            />
          </div>
          <div className={classes.cardSection}>
            <Typography variant="h2" component="span" color="primary">
              {formatPrice(tariff.price)}
            </Typography>
            <Typography component="span"> a month</Typography>
          </div>
        </CardContent>
        <CardActions>
          <Button
            variant={tariffSelected ? 'contained' : 'outlined'}
            size="large"
            fullWidth
            onClick={() => onSelectTariff(tariff)}
            data-test-id={`mobileTariff${tariff.name}`}
          >
            {tariffSelected ? 'Selected' : 'Select'}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

const getTariffDesc = (label, discountedPrice) => {
  if ([tariffLabels.UNLIMITED_PLUS, tariffLabels.UNLIMITED].includes(label)) {
    return (
      <>
        After your first SIM, get up to three more for only{' '}
        <span>
          <strong>{`${formatPrice(discountedPrice)} a month`}</strong>
        </span>{' '}
        each
      </>
    );
  }

  if ([tariffLabels.ESSENTIAL, tariffLabels.VALUE].includes(label)) {
    return 'Our low-cost, everyday plan for customers who use less data';
  }

  return '';
};

const Benefits = ({ label, data, dataUnits }) => {
  const classes = useStyles();
  switch (label) {
    case tariffLabels.ESSENTIAL:
      return (
        <>
          <Typography className={classes.benefitRow}>
            <TickMediumIcon className={classes.tick} />
            <strong>8GB</strong>&nbsp;monthly data
          </Typography>
          <Typography className={classes.benefitRow}>
            <TickMediumIcon className={classes.tick} />
            <strong>4G</strong>&nbsp;and Wi-Fi calling
          </Typography>
          <br />
          <br />
        </>
      );
    case tariffLabels.UNLIMITED_PLUS:
      return (
        <>
          <Typography className={classes.benefitRow}>
            <TickMediumIcon className={classes.tick} />
            <strong>Unlimited </strong> &nbsp;monthly data
          </Typography>
          <Typography className={classes.benefitRow}>
            <TickMediumIcon className={classes.tick} />
            <strong>5G, 4G </strong>&nbsp;and Wi-Fi calling
          </Typography>
          <Typography className={classes.benefitRow}>
            <TickMediumIcon className={classes.tick} />
            Inclusive <strong>&nbsp;EU roaming</strong>
          </Typography>
        </>
      );
    case tariffLabels.VALUE:
    case tariffLabels.UNLIMITED:
      return (
        <>
          <Typography variant="h2" color="primary">
            {formatDataUsage({ data, dataUnits })}
          </Typography>
          <Typography>Monthly data</Typography>
        </>
      );
    default:
      return null;
  }
};

TariffSelectionCard.propTypes = {
  onSelectTariff: PropTypes.func.isRequired,
  tariffSelected: PropTypes.bool.isRequired,
  hasMultiSimOffer: PropTypes.bool.isRequired,
  tariff: tariffPropType,
};

export default React.memo(TariffSelectionCard);
