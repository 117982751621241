import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RadioButtonGroup from 'modules/Shared/MultiSelection/RadioButtonGroup';
import Dialog from 'modules/Shared/Dialog';
import RestrictedButton from 'app/redux/modules/RemoteSession/v2/RestrictedButton';

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.spacing(3),
  },
  radioGroup: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(max-content, 140px))',
  },
  saveButton: {
    margin: theme.spacing(3, 0),
  },
}));

const SpendCapDialog = ({
  open,
  onClose,
  spendCapSelected,
  setSpendCapSelected,
  submit,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} title={'Your spend cap options'}>
      <Typography className={classes.section}>
        If you turn your spend cap{' '}
        <span>
          <strong>on</strong>
        </span>
        , you'll need to add a top-up to use chargeable services outside your
        plan like roaming outside the EU, or calling premium numbers
      </Typography>
      <RadioButtonGroup
        options={[
          { value: true, label: 'On' },
          { value: false, label: 'Off' },
        ]}
        radioGroupClasses={{
          container: classes.section,
          radioGroup: classes.radioGroup,
        }}
        label="Would you like your spend cap on or off for this SIM?"
        value={spendCapSelected}
        onChange={(event) => {
          const value = event.target.value === 'true';
          setSpendCapSelected(value);
        }}
      />
      <RestrictedButton
        className={classes.saveButton}
        onClick={() => {
          submit();
        }}
        label="Save"
        variant="contained"
        size={'large'}
      />
    </Dialog>
  );
};

SpendCapDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  spendCapSelected: PropTypes.bool.isRequired,
  setSpendCapSelected: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default SpendCapDialog;
