import React from 'react';
import { Button, CardContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { tariffPropType } from 'app/redux/modules/Mobile/constants';
import Tile from 'modules/Mobile/SimSelection/Tile';
import TariffPrice from 'modules/Shared/PriceWithDiscount';
import makeStyles from '@material-ui/core/styles/makeStyles';

import useTileStyles from './styles';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    height: '100%',
    gap: theme.spacing(1, 0),
  },
  card: {
    marginBottom: theme.spacing(6),
    backgroundColor: 'transparent',
    borderStyle: 'dashed',
    borderColor: theme.palette.text.primary,
    borderWidth: 1,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
  buttonLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const AddSimTile = ({ tariff, discounted, addProduct }) => {
  const classes = useStyles();
  const tileClasses = useTileStyles();
  return (
    <Tile cardClass={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography
          variant="h4"
          className={tileClasses.title}
        >{`${tariff.name} SIM`}</Typography>
        <TariffPrice
          includeStrikethrough
          price={tariff.price}
          discountedPrice={discounted ? tariff.discountedPrice : undefined}
        />
        <Button
          variant={'outlined'}
          fullWidth
          onClick={addProduct}
          classes={{ label: classes.buttonLabel }}
        >
          Add
        </Button>
      </CardContent>
    </Tile>
  );
};

AddSimTile.propTypes = {
  tariff: tariffPropType,
  discounted: PropTypes.bool.isRequired,
  addProduct: PropTypes.func.isRequired,
};

export default AddSimTile;
