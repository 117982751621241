import React, { useState } from 'react';
import { Box, Link, List, ListItem, Typography } from '@material-ui/core';
import ContentDropdown from 'components/modules/Shared/ContentDropdown';
import {
  MOBILE_FAIR_USE,
  MOBILE_TARIFF_INFO,
  MOBILE_TERMS,
} from 'config/externalLinks';

const Index = () => {
  const [legalOpen, setLegalOpen] = useState(false);
  return (
    <Box pb={4} pt={4}>
      <ContentDropdown
        label="Legal bit"
        open={legalOpen}
        onOpen={() => setLegalOpen(!legalOpen)}
      >
        <List>
          <ListItem>
            <Typography>
              Limited time offer - get inclusive EU roaming on our Essential
              plan until 30th September 2024. £2 daily charge after 30th
              September - subject to your 8GB data allowance.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              Our Unlimited+ plan gives you access to 4G and 5G with a
              compatible handset. Inclusive EU Roaming, subject to a 14GB
              roaming cap. Data consumption subject to a personal monthly fair
              usage amount of 500GB.
            </Typography>
          </ListItem>
          <ListItem>
            <Link href={MOBILE_TERMS} target="_blank" rel="noreferrer noopener">
              <Typography>Terms & Conditions</Typography>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href={MOBILE_TARIFF_INFO}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Typography>Tariff Information</Typography>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href={MOBILE_FAIR_USE}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Typography>Fair use policy</Typography>
            </Link>
          </ListItem>
        </List>
      </ContentDropdown>
    </Box>
  );
};

export default Index;
