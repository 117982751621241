import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import Button from 'modules/Shared/Button';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';
import FormRow from './FormRow';
import styles from './styles';
import copy from './copy';

const MobileContractRenewal = ({
  classes,
  contracts,
  updateField,
  validate,
  submit,
  remove,
  formComplete,
  submitted,
  loading,
  error,
}) => {
  const addRow = () => updateField(contracts.length);
  const showForm = !submitted && !loading && !error;

  const renderForm = () => (
    <div className={classes.form}>
      {contracts.map((contract, i) => (
        <FormRow
          id={i}
          mobile={contract.mobile}
          date={contract.date}
          errors={contract.errors}
          updateField={(field, value) => updateField(i, field, value)}
          validate={() => validate(contracts)}
          remove={contracts.length > 1 ? () => remove(i) : undefined}
          key={i}
        />
      ))}

      <div className={classes.ctaContainer}>
        {contracts.length < 4 && (
          <Button
            classNames={{ root: classes.addNewButton }}
            colorStyle="purple"
            label="Add New"
            onClick={addRow}
          />
        )}
        {contracts.length >= 4 && <div />}
        <Button
          classNames={{ root: classes.submitButton }}
          colorStyle="yellow"
          label="Submit"
          onClick={formComplete ? submit : undefined}
          disabled={!formComplete}
        />
      </div>
    </div>
  );

  return (
    <Card className={classes.container} variant="outlined">
      <CardContent className={classes.content}>
        <Typography component="h3" paragraph variant="h3">
          {copy.title}
        </Typography>
        <Typography paragraph>{copy.description}</Typography>
        {showForm && renderForm()}
        {loading && <LoadingIndicator loading />}
        {submitted && (
          <Typography className={classes.submitted} paragraph>
            {copy.thanks}
          </Typography>
        )}
        {error && (
          <Typography className={classes.error} paragraph>
            {copy.error}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

MobileContractRenewal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileContractRenewal);
