import React from 'react';
import PropTypes from 'prop-types';
import Card from 'modules/Shared/Card/Card';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 250,
      gap: theme.spacing(2, 0),
    },
  },
  card: {
    width: '100%',
    height: '100%',
  },
}));

const Tile = ({ children, actionButton, cardClass }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card classes={{ root: classnames(classes.card, cardClass) }}>
        {children}
      </Card>
      {actionButton}
    </div>
  );
};

Tile.propTypes = {
  children: PropTypes.node.isRequired,
  actionButton: PropTypes.node,
  cardClass: PropTypes.string,
};

export default Tile;
