import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from 'modules/Shared/Dialog';
import AlertMessage from 'modules/Shared/AlertMessage';
import RadioButtonGroup from 'modules/Shared/MultiSelection/RadioButtonGroup';
import TextField from 'modules/CustomerUI/TextField';
import RestrictedButton from 'app/redux/modules/RemoteSession/v2/RestrictedButton';
import {
  requiresExistingNumber,
  requiresPacCode,
  requiresStacCode,
  validateForm,
} from 'app/redux/modules/Mobile/validation';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 500,
  },
  section: {
    marginTop: theme.spacing(2),
  },
  radioGroup: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(max-content, 140px))',
  },
  codeInfo: { marginTop: theme.spacing(1) },
  saveButton: {
    margin: theme.spacing(2, 0, 3, 0),
  },
}));

const NumberOptionsDialog = ({
  open,
  values,
  errors,
  updateValue,
  onClose,
  submit,
}) => {
  const classes = useStyles();
  const {
    keepMyNumber,
    cancelOldContract,
    existingNumber,
    pacCode,
    stacCode,
  } = values;

  const existingNumberRequired = useMemo(() => requiresExistingNumber(values), [
    values,
  ]);
  const pacCodeRequired = useMemo(() => requiresPacCode(values), [values]);
  const stacCodeRequired = useMemo(() => requiresStacCode(values), [values]);
  const formValid = useMemo(() => validateForm(values, errors), [
    values,
    errors,
  ]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={'Your number options'}
      className={classes.dialog}
    >
      <AlertMessage>
        Don't worry, you can also choose these options in your online account
        after you sign up.
      </AlertMessage>
      <RadioButtonGroup
        options={[
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ]}
        radioGroupClasses={{
          container: classes.section,
          radioGroup: classes.radioGroup,
        }}
        label="Would you like to keep an existing mobile number for this SIM?"
        value={keepMyNumber}
        onChange={(event) => {
          const value = event.target.value === 'true';
          updateValue('keepMyNumber', value);
        }}
      />
      {!pacCodeRequired && (
        <RadioButtonGroup
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]}
          radioGroupClasses={{
            container: classes.section,
            radioGroup: classes.radioGroup,
          }}
          label="Would you like us to cancel your contract with your current mobile provider?"
          value={cancelOldContract}
          onChange={(event) => {
            const value = event.target.value === 'true';
            updateValue('cancelOldContract', value);
          }}
        />
      )}
      {existingNumberRequired && (
        <TextField
          fullWidth
          onChange={(_, value) => updateValue('existingNumber', value)}
          label={`Number you'd like to ${keepMyNumber ? 'keep' : 'cancel'}`}
          value={existingNumber}
          error={errors.existingNumber}
          errorMessage={errors.existingNumber}
        />
      )}
      {pacCodeRequired && (
        <>
          <TextField
            fullWidth
            onChange={(_, value) => updateValue('pacCode', value)}
            label="PAC code"
            value={pacCode}
            error={errors.pacCode}
            errorMessage={errors.pacCode}
          />
          <Typography className={classes.codeInfo} variant="body2">
            Text PAC to 65075 from the number you’d like to keep
          </Typography>
        </>
      )}
      {stacCodeRequired && (
        <>
          <TextField
            fullWidth
            onChange={(_, value) => updateValue('stacCode', value)}
            label="STAC code"
            value={stacCode}
            error={errors.stacCode}
            errorMessage={errors.stacCode}
          />
          <Typography className={classes.codeInfo} variant="body2">
            Text STAC to 75075 from the number you’d like to keep
          </Typography>
        </>
      )}
      <RestrictedButton
        className={classes.saveButton}
        onClick={() => {
          submit();
        }}
        label="Save"
        variant="contained"
        size={'large'}
        disabled={!formValid}
      />
    </Dialog>
  );
};

NumberOptionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    keepMyNumber: PropTypes.bool.isRequired,
    cancelOldContract: PropTypes.bool.isRequired,
    existingNumber: PropTypes.string,
    pacCode: PropTypes.string,
    stacCode: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    existingNumber: PropTypes.string,
    pacCode: PropTypes.string,
    stacCode: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  updateValue: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default NumberOptionsDialog;
