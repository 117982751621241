import { makeStyles } from '@material-ui/core/styles';

const style = ({ spacing, palette, typography }) => ({
  card: {
    borderWidth: 0,
  },
  content: {
    flexGrow: 1,
    padding: spacing(0, 2),
  },
  cardSection: {
    padding: spacing(2, 0),
    textAlign: 'center',
    '& + &': {
      borderTop: `2px solid ${palette.background.default}`,
    },
  },
  cardTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  topBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: spacing(0, 3),
  },
  multiSimHidden: {
    visibility: 'hidden',
  },
  multiSimContainer: {
    display: 'flex',
    backgroundColor: palette.success.main,
    justifyContent: 'center',
    padding: spacing(0.5, 1, 0.25, 1),
    borderTopLeftRadius: spacing(1),
    borderTopRightRadius: spacing(1),
    width: 'fit-content',
  },
  multiSimText: {
    lineHeight: 1.2,
    color: palette.primary.contrastText,
    fontWeight: typography.fontWeightBold,
  },
  benefitRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingRight: spacing(2.5),
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  tick: {
    color: palette.success.main,
    marginRight: spacing(1),
  },
});

export default makeStyles(style);
