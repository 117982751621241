import React from 'react';
import Card from 'components/modules/Shared/Card/Card';
import CardHeader from 'components/modules/Shared/Card/CardHeader';
import { Box, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from './styles';

const CardSkeleton = () => {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader title={<Skeleton animation="wave" />} />
      <CardContent>
        <div className={classes.cardSection}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </div>
        <div className={classes.cardSection}>
          <Box mt={2} mb={2}>
            <Skeleton
              animation="wave"
              variant="rect"
              height={48}
              width={140}
              style={{ display: 'inline-block' }}
            />
          </Box>
        </div>
        <div className={classes.cardSection}>
          <Box mt={2} mb={2}>
            <Skeleton
              animation="wave"
              variant="rect"
              height={48}
              width={140}
              style={{ display: 'inline-block' }}
            />
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default React.memo(CardSkeleton);
