import React from 'react';
import marked from 'marked';
import Radium from 'radium';
import DOMPurify from 'dompurify';

function LinkRenderer(href, title, text) {
  return `<a href="${href}" target="_blank">${text}</a>`;
}

//TODO: deprecated, to be removed
class Markdown extends React.Component {
  constructor(props) {
    super(props);

    const options = {
      gfm: true,
      tables: true,
      breaks: false,
      pedantic: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
      ...this.props.options,
    };

    marked.setOptions(options);

    const renderer = new marked.Renderer();

    if (this.props.renderers) {
      Object.keys(this.props.renderers).forEach((k) => {
        renderer[k] = this.props.renderers[k];
      });
    }

    renderer.link = LinkRenderer;

    this.state = { renderer };
  }

  content(style) {
    return React.Children.map(this.props.children, (child) => {
      if (typeof child === 'string') {
        const html = marked(child, { renderer: this.state.renderer });
        const sanitized = DOMPurify.sanitize(html);
        return (
          <div
            dangerouslySetInnerHTML={{ __html: sanitized }}
            style={style ? { ...style } : {}}
          />
        );
      }
      return child;
    });
  }

  render() {
    const { className, textStyle, style } = this.props; // textStyle only used for paragraph
    return (
      <div className={className} style={style}>
        {this.content(textStyle)}
      </div>
    );
  }
}

export default Radium(Markdown);
