import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';

import TextField from 'modules/Shared/TextField';
import DateInput from 'modules/Shared/DateInput';
import styles from './styles';

const errorText = (errObj) => (errObj ? errObj.error : undefined);

const FormRow = ({
  classes,
  id,
  mobile,
  date,
  errors,
  updateField,
  validate,
  remove,
}) => (
  <div className={classes.formRow}>
    <TextField
      dataTestId={`MobileRenewalDate${id}`}
      className={classes.mobileField}
      fullWidth={false}
      label={`Mobile number ${id + 1}`}
      placeholder="07111111111"
      value={mobile}
      errorMessage={errorText(errors.mobile)}
      error={!isEmpty(errorText(errors.mobile))}
      onChange={(e, value) => {
        if (value === '' || (/^\d*$/.test(value) && value.length < 12)) {
          updateField('mobile', value);
        }
      }}
      onBlur={validate}
      margin="dense"
    />
    <div className={classes.formDate}>
      <DateInput
        dataTestId={`MobileRenewalDate${id}`}
        month={date ? moment(date, 'MM-YYYY').get('month') + 1 : undefined}
        year={date ? moment(date, 'MM-YYYY').get('year') : undefined}
        errorText={errorText(errors.date) || undefined}
        onChange={(value) => {
          const formatted = value ? moment(value).format('MM-YYYY') : undefined;
          updateField('date', formatted);
        }}
        validate={validate}
      />
      {remove && (
        <Button onClick={remove} variant="outlined">
          Remove
        </Button>
      )}
    </div>
  </div>
);

FormRow.defaultProps = {
  classes: {},
};

FormRow.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  mobile: PropTypes.string,
  date: PropTypes.string,
  errors: PropTypes.object,
  updateField: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  remove: PropTypes.func,
};

export default withStyles(styles)(FormRow);
