import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const style = (theme) => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(0, 1),
  },
  title: {
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  infoRow: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  infoLabel: {
    margin: `auto ${theme.spacing(0.5)}px auto 0`,
  },
  separator: {
    width: '100%',
    borderStyle: 'solid',
    borderColor: fade(theme.palette.text.primary, 0.4),
    borderBottom: 'none',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
    },
  },
  editSimButton: {
    padding: 0,
    minWidth: 0,
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    textDecorationColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '2px',
      textDecorationColor: theme.palette.secondary.light,
      color: theme.palette.secondary.light,
    },
  },
  editSimButtonText: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(0.25),
    fontWeight: theme.typography.fontWeightMedium,
    textUnderlinePosition: 'under',
  },
  removeButton: {
    width: 'fit-content',
    display: 'flex',
    padding: theme.spacing(0.25, 0.5),
    justifyContent: 'centre',
    cursor: 'pointer',
  },
  removeLabel: {
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
  },
  smallText: {
    fontSize: 14,
  },
});

export default makeStyles(style);
